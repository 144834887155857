import React ,{useState} from "react";
import Dropdown from 'react-bootstrap/Dropdown'
import { Helmet } from "react-helmet";
const HowTo = (props) => {
  const [filter,setFilter]=useState("latest");

  return (
    <div className={`howTo blogHeader`}>
      <div className="HowToVideos">
        <div className="videoHeader">
          <h3 className="videoTitle">Blog</h3>
          <div className="videoTabs">
            <span className={(filter=="latest")?"active-tab":""}
            onClick={()=>{props.filterDataList('');setFilter("latest")}}>Latest Articles</span>
            <span className={(filter=="project management")?"active-tab":""}
            onClick={()=>{props.filterDataList('project management');setFilter("project management")}}>Project Management</span>
            <span className={(filter=="agile")?"active-tab":""}
            onClick={()=>{props.filterDataList('agile');setFilter("agile")}}>Agile</span>
            <span className={(filter=="operations & productivity")?"active-tab":""}
            onClick={()=>{props.filterDataList('operations & productivity');setFilter("operations & productivity")}}>Operations & Productivity</span>
          </div>
          <Dropdown className="dropdownMenu">
            <Dropdown.Toggle variant="success" id="dropdown-basic">
              Filter
            </Dropdown.Toggle>
            <Dropdown.Menu>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('')}>Latest Articles</Dropdown.Item>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('project management')}>Project Management</Dropdown.Item>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('agile')}>Agile</Dropdown.Item>
              <Dropdown.Item href="#" onClick={()=>props.filterDataList('operations & productivity')}>Operations & Productivity</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <div className="tabLine tabBlogLine"></div>
        <div className="videoOrder">
        </div>
        
      </div>
      <Helmet
          htmlAttributes={{
            // lang,
          }}
          title='Blog | Oversight'
          titleTemplate={`Blog | Oversight`}
        />
    </div>
  );
};

export default HowTo;
