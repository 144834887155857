import React, { useState } from 'react'
import Layout from '../components/layout'
import { useStaticQuery, graphql, Link } from 'gatsby'
import BlogList from '../components/callouts/blogList'

const Blog = () => {

  const indexQuery = useStaticQuery (
    graphql`query pageQuery {
      pages: allContentfulBlogPost {
        edges {
          node {
            id
            slug
            __typename
            readTime
            date(formatString: "MMM DD, YYYY")
            page {
              title
              updatedAt(fromNow: false)
              id
            }
            featuredImage {
              file {
                url
              }
            }
            byline {
              graphic {
                file {
                  url
                }
              }
              title
            }
            post {
              id
              post
            }
            tags
            title
          }
        }
      }
    }`
  )

  const data = indexQuery.pages.edges;
  const  [filteredList, setFilteredList ]= useState(indexQuery.pages.edges)

    const filterDataList=(filter)=>{
      console.log('filter data list', filter)
    if(filter===""){
      setFilteredList(data);
    }else{
      let newList = data.filter((item) => {
        console.log('item', filter)
        return item.node.tags.includes(filter);
      });
      setFilteredList(newList);
    }
  }

  const filterBySearchText=(search)=>{
    let newList = data.filter(function(item){
      if(item.node.title.toLocaleLowerCase().includes(search))
      return item;
    });
    setFilteredList(newList);
  }
  
  return <Layout pageInfo={{ pageName: 'blog' }} >
      <div className="">
        <BlogList filterDataList={filterDataList} filterBySearchText={filterBySearchText}/>
      </div>
      <div className="videoOrder row">
        {filteredList.map((blogList,index) => {
          const { title, tags, date, readTime, byline, featuredImage, slug } = blogList.node;
          return (index==0)?(<div className="col-12">
            <div className="feature-articles" >
              <div className="feature">
                  <ul className="tags">{tags.map((tag) => (<li className="taggers">{tag}</li>))}</ul>
                      <h4 className="titleName">
                          <Link to={`/${slug}`}>{title}</Link>
                      </h4>
                      <div className='clock'>
                          <span className='date'>{date}</span>
                          <div className="dateLine"></div>
                          <span className='time'>{readTime} read</span>
                      </div>
                      <span className='excerpt'></span>
                      {byline!==undefined &&
                      <div className='authorDiv'>
                          <img className='authImg' src={byline.graphic.file.url} /> 
                          <span className='author'>by {byline.title}</span>
                      </div>}
                  </div>
                  <div className="img">
                    <Link to={`/${slug}`} className=''>
                      <img className='mainImg' src={featuredImage.file.url}/>
                    </Link>
                  </div>
              </div>
            </div>
          ):(

            <div className="col-lg-4 ">
              <div className="list-articles" >
                  <div className="feature">
                  <ul className="tags">
                  {tags.map((tag) => (
                      <li className="taggers">{tag}</li>
                      ))}
                  </ul>
                      <h4 className="titleName">
                          <Link to={`/${slug}`}>{title}</Link>
                      </h4>
                      <div className='clock'>
                          <span className='date'>{date}</span>
                          <div className="dateLine"></div>
                          <span className='time'>{readTime} read</span>
                      </div>
                      <span className='excerpt'></span>
                      {byline!==undefined &&
                      <div className='authorDiv'>
                          <img className='authImg' src={byline.graphic.file.url} /> 
                          <span className='author'>by {byline.title}</span>
                      </div>}
                  </div>
                  <div className="img">
                      <Link to={`/${slug}`}>
                        <img className='mainImg' src={featuredImage.file.url}/>
                      </Link>
                  </div>
              </div>
            </div>
          );
      })}
    </div>
  </Layout>
}

export default Blog;